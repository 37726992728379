import React from "react"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/es/integration/react"
import { getPersistor } from "@rematch/persist"

import store from "@store"

export default ({ element }) => (
  <Provider store={store}>
    <PersistGate persistor={getPersistor()}>{element}</PersistGate>
  </Provider>
)
