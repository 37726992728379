export const pxToRem = (pxSize, rootSize = 16) => {
  return (pxSize / rootSize).toFixed(2)
}

export const rem = pxSize => {
  return `${pxToRem(pxSize)}rem`
}

export const colors = {
  black: "#1b1b1b",
  navyBlack: "#16202B",
  blue: "#140FEB",
  orange: "#FF5E33",
  white: "#FFFFFF",
  offWhite: "#f9f9f9",
  green: "#2EAA53",
  red: "#FD0B0B",
  pink: "#CD00EE",
  purple: "#9D11B4"
}

export const breakpoints = [
  rem(600), //0
  rem(900), //1
  rem(1200), //2
  rem(1400) //3
]

export const fontSizes = [
  rem(13), //0
  rem(16), //1
  rem(24), //2
  rem(38) //3
]

export const spacePx = [0, 4, 8, 12, 16, 24, 32, 48, 64, 128, 256, 512]
export const space = spacePx.map(i => rem(i))

export const fonts = {
  display: "'NeueDisplay', sans-serif",
  text: "'NeueText', serif"
}

// Theme Settings
export const settings = {
  maxWidth: rem(890),
  background: colors.white,
  color: colors.black
}

// Export Theme Provider
const Theme = {
  colors,
  breakpoints,
  fontSizes,
  space,
  fonts
}

export default Theme
