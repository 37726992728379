import React from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import "minireset.css/minireset.css"
import theme, { fonts, rem, colors } from "@src/theme"

import neueDisplay from "./fonts/Neue_Display-Medium.otf"
import neueText from "./fonts/NeuePlakText-Regular.otf"

export const GlobalStyle = createGlobalStyle`


@font-face {
    font-family: NeueDisplay;
    src: url(${neueDisplay}) format('opentype');
    font-weight: 500;
    font-style: normal;
  }
@font-face {
    font-family: NeueText;
    src: url(${neueText}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  * {
    letter-spacing: 0.015em;
  }

  
  html {
    -webkit-print-color-adjust: exact;
    font-family: 'NeueText', sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.25;
    font-size: ${rem(18)};
    background: #fff;
    position: relative;
    
  }
  
  h1, h2, h3, h4 {
    font-weight: 500;
    font-family: ${fonts.display};
  }



  button {
    background: none;
	  color: inherit;
	  border: none;
	  padding: 0;
	  font: inherit;
	  cursor: pointer;
	  outline: inherit;
    &:disabled {
      opacity: 0.5;
    }
  }

  a {
    color: ${colors.black};
  }

`

export default ({ element, props }) => {
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>{element}</ThemeProvider>
    </>
  )
}
