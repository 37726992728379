export const user = {
  state: {
    id: "",
    profile: {}
  },
  reducers: {
    setUser(state, payload) {
      return {
        ...state,
        ...payload
      }
    }
  },
  effects: dispatch => ({
    async fetch(payload, state) {}
  })
}
