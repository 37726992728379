import { init } from "@rematch/core"
import createRematchPersist from "@rematch/persist"
import * as models from "./models"

const persistPlugin = createRematchPersist({
  version: 1,
  whitelist: ["user"],
})

export default init({
  models,
  plugins: [persistPlugin],
})
